import {
  Building,
  BuildingPart,
  Campus,
  CampusPart,
  Floor,
  FloorArea,
  MultifloorArea,
  Outside,
  Room,
  RoomSegment,
} from '../../apis/sbs-location-api';

export interface LoadSingleFloorDataBatch {
  next: boolean;
  pageNumber: number;
  data: (
    | Campus
    | CampusPart
    | Building
    | BuildingPart
    | Outside
    | Floor
    | FloorArea
    | MultifloorArea
    | Room
    | RoomSegment
  )[];
}

export interface PageRequest {
  // The requested page number
  offset: number;
  // The requested page size
  pageSize: number;
  // An optional filter parameter
  filteredElements?: ImportJobDetails[];
}

export class Page {
  // The number of elements in the page
  size = 0;
  // The total number of elements
  totalElements = 0;
  // The total number of pages
  totalPages = 0;
  // The current page number
  pageNumber = 0;
}

export class PagedData<T> {
  data: T[] = new Array<T>();
  page: Page = new Page();
}

export interface ImportJobDetails {
  floorName: string;
  floorLevel: string;
  uploadedDate: string;
  expectedCompletionTime: string;
  type: string;
  service: string;
  status: string;
  statusDescription: string;
  failureType?: string;
  floorId: string;
  importJobId: string;
}

export interface HistoryTablePermissions {
  canCreateFloorplan: boolean;
}
