import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GetImportJobsResponseDataAttributes } from '../apis/beff-api';

@Pipe({
  name: 'importStatusTranslate',
})
export class ImportStatusTranslatePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}
  transform(inputValue: string): string {
    // If translation service is not initialized, return the original value
    if (
      !this.translateService.currentLang &&
      !this.translateService.defaultLang
    ) {
      return inputValue;
    }

    let translateKey = '';
    switch (inputValue) {
      case GetImportJobsResponseDataAttributes.StatusEnum.Converted:
        translateKey = 'IMPORT_HISTORY_STATUSES.CONVERTED';
        break;
      case GetImportJobsResponseDataAttributes.StatusEnum.Running:
        translateKey = 'IMPORT_HISTORY_STATUSES.RUNNING';
        break;
      case GetImportJobsResponseDataAttributes.StatusEnum.Importing:
        translateKey = 'IMPORT_HISTORY_STATUSES.IMPORTING';
        break;
      case GetImportJobsResponseDataAttributes.StatusEnum.Imported:
        translateKey = 'IMPORT_HISTORY_STATUSES.IMPORTED';
        break;
      case GetImportJobsResponseDataAttributes.StatusEnum.Failed:
        translateKey = 'IMPORT_HISTORY_STATUSES.FAILED';
        break;
      case GetImportJobsResponseDataAttributes.StatusEnum.New:
        translateKey = 'IMPORT_HISTORY_STATUSES.NEW';
        break;
    }
    // Use translate service to get the translation
    return this.translateService.instant(translateKey);
  }
}
