/**
 * Plan Import App BFF
 * Plan Import App BFF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Artifacts } from './artifacts';
import { GetImportJobsResponseDataAttributesMeta } from './get-import-jobs-response-data-attributes-meta';
import { FloorplanRelationships } from './floorplan-relationships';


export interface GetImportJobsResponseDataAttributes { 
    /**
     * Type of import method
     */
    jobType: GetImportJobsResponseDataAttributes.JobTypeEnum;
    /**
     * status of the import job
     */
    status: GetImportJobsResponseDataAttributes.StatusEnum;
    /**
     * more info about import job status
     */
    statusDescription: string;
    /**
     * more info about job failure. Applicable only if job status is FAILED.
     */
    failureType?: GetImportJobsResponseDataAttributes.FailureTypeEnum;
    artifacts: Array<Artifacts>;
    relationships: FloorplanRelationships;
    meta: GetImportJobsResponseDataAttributesMeta;
}
export namespace GetImportJobsResponseDataAttributes {
    export type JobTypeEnum = 'AZURE' | 'POINTR';
    export const JobTypeEnum = {
        Azure: 'AZURE' as JobTypeEnum,
        Pointr: 'POINTR' as JobTypeEnum
    };
    export type StatusEnum = 'NEW' | 'RUNNING' | 'CONVERTED' | 'IMPORTING' | 'IMPORTED' | 'FAILED';
    export const StatusEnum = {
        New: 'NEW' as StatusEnum,
        Running: 'RUNNING' as StatusEnum,
        Converted: 'CONVERTED' as StatusEnum,
        Importing: 'IMPORTING' as StatusEnum,
        Imported: 'IMPORTED' as StatusEnum,
        Failed: 'FAILED' as StatusEnum
    };
    export type FailureTypeEnum = 'PROCESSABLE' | 'UNPROCESSABLE';
    export const FailureTypeEnum = {
        Processable: 'PROCESSABLE' as FailureTypeEnum,
        Unprocessable: 'UNPROCESSABLE' as FailureTypeEnum
    };
}


