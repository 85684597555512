import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { BeffConfiguration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { ArtifactsBeffService } from './api/artifacts-service';
import { CORSBeffService } from './api/cors-service';
import { ImportJobsBeffService } from './api/import-jobs-service';
import { StateBeffService } from './api/state-service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: []
})
export class BeffApiModule {
    public static forRoot(configurationFactory: () => BeffConfiguration): ModuleWithProviders<BeffApiModule> {
        return {
            ngModule: BeffApiModule,
            providers: [ { provide: BeffConfiguration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: BeffApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('BeffApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
