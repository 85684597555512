/**
 * BX Location Service v2
 * Location Service implementation is following: - Location model: [BTLO 1.1](https://bt.schema.siemens.io/bt/shared/btlo/version/1.1) - Zones model: [BTZF 2.2](https://bt.schema.siemens.io/bt/shared/btzf/version/2.2) 
 *
 * The version of the OpenAPI document: 2.5.12
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Represents a flat, two-dimensional geometry
 */
export interface BuildingAttributesGeoAreaGeometry { 
    type: BuildingAttributesGeoAreaGeometry.TypeEnum;
    coordinates: Array<Array<Array<number>>>;
}
export namespace BuildingAttributesGeoAreaGeometry {
    export type TypeEnum = 'Polygon';
    export const TypeEnum = {
        Polygon: 'Polygon' as TypeEnum
    };
}


