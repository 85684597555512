import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UploadFile } from '@simpl/element-ng';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent {
  @Output() fileOutput: EventEmitter<any> = new EventEmitter();
  @Input() acceptedTypes: string;
  file!: UploadFile;

  filesAdded(files: UploadFile[]) {
    this.file = files[0];
    this.fileOutput.emit(files[0]);
  }

  removeFile() {
    this.file = undefined;
    this.fileOutput.emit(null);
  }
}
