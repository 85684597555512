/**
 * BX Location Service v2
 * Location Service implementation is following: - Location model: [BTLO 1.1](https://bt.schema.siemens.io/bt/shared/btlo/version/1.1) - Zones model: [BTZF 2.2](https://bt.schema.siemens.io/bt/shared/btzf/version/2.2) 
 *
 * The version of the OpenAPI document: 2.5.12
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Represents a Floor
 */
export interface Floor1 { 
    id: string;
    type: Floor1.TypeEnum;
}
export namespace Floor1 {
    export type TypeEnum = 'Floor';
    export const TypeEnum = {
        Floor: 'Floor' as TypeEnum
    };
}


