/**
 * Plan Import App BFF
 * Plan Import App BFF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { GetArtifactsAttributes } from './get-artifacts-attributes';


export interface GetArtifacts { 
    /**
     * Import Job Id
     */
    id: string;
    type: GetArtifacts.TypeEnum;
    attributes: GetArtifactsAttributes;
}
export namespace GetArtifacts {
    export type TypeEnum = 'ImportJob';
    export const TypeEnum = {
        ImportJob: 'ImportJob' as TypeEnum
    };
}


