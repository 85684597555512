import { Injectable } from '@angular/core';
import {
  catchError,
  EMPTY,
  expand,
  filter,
  from,
  map,
  mergeMap,
  Observable,
  of,
  reduce,
  switchMap,
  toArray,
} from 'rxjs';

import { TranslateService } from '@ngx-translate/core';
import { SiToastNotificationService } from '@simpl/element-ng';
import {
  GetImportJobsResponseData,
  GetImportJobsResponseDataAttributes,
  ImportJobsBeffService,
} from '../apis/beff-api';
import { Floor, LocationsService } from '../apis/sbs-location-api';
import {
  ImportJobDetails,
  LoadSingleFloorDataBatch,
  Page,
  PagedData,
  PageRequest,
} from '../components/interfaces/file-upload-history.interface';

@Injectable({
  providedIn: 'root',
})
export class FileUploadHistoryService {
  elements: ImportJobDetails[] = [];
  floorData: Floor[] = [];
  availableFloorLevels: number[];

  constructor(
    public locationsService: LocationsService,
    public importJobsBeffService: ImportJobsBeffService,
    private toastService: SiToastNotificationService,
    private translate: TranslateService
  ) {}

  //Method to get all the floorIds for the corresponding building
  private _getAllFloorData(buildingId: string, pageNumber: number = 0) {
    return this._getSingleBatchFloorData(buildingId, pageNumber).pipe(
      expand(res => {
        if (res?.next) {
          return this._getSingleBatchFloorData(buildingId, res.pageNumber);
        } else {
          return EMPTY;
        }
      }),
      map(res => res.data),
      reduce((acc, cur) => {
        return acc.concat(cur);
      }, []),
      map(floorData => {
        this.floorData = floorData;
        this.availableFloorLevels =
          this._extractAvailableFloorLevels(floorData);
        return floorData.map(data => data.id);
      })
    );
  }

  /**
   * Extracting all floor levels that are used up
   * This info will be used to validate while initiating a new import that creates
   * a new floor on a unique floor level
   * @param res details of all floors that are part of building
   * @returns
   */
  private _extractAvailableFloorLevels(res: any[]) {
    const availableFloors: number[] = [];
    res.forEach(floor => {
      if (
        floor.attributes.floorNumber ||
        Number.isInteger(floor.attributes.floorNumber)
      ) {
        availableFloors.push(+floor.attributes.floorNumber);
      }
    });
    return availableFloors;
  }

  private _getSingleBatchFloorData(
    buildingId: string,
    pageNumber: number
  ): Observable<LoadSingleFloorDataBatch> {
    const pageSize: number = undefined;
    const include: any = undefined;
    const filterType = 'Floor';
    const filterLabel: any = undefined;
    const filterExternalId: any = undefined;
    const filterTagsTagKey: any = undefined;
    const filterHasPostalAddressDataId: any = undefined;
    const filterIsPartOfDataType = 'Building';
    return this.locationsService
      .listLocation(
        pageSize,
        pageNumber,
        include,
        filterType,
        filterLabel,
        filterExternalId,
        filterTagsTagKey,
        filterHasPostalAddressDataId,
        buildingId,
        filterIsPartOfDataType
      )
      .pipe(
        map(result => {
          const isNext = !!result?.links?.next;
          const pageNum = isNext
            ? result.links.next.split('?')[1].split('&')[3].split('=')[1]
            : 0;
          return {
            next: isNext,
            pageNumber: Number(pageNum),
            data: result?.data,
          };
        })
      );
  }

  getAllImportJobs(
    partitionId: string,
    buildingId: string
  ): Observable<ImportJobDetails[]> {
    return this._getAllFloorData(buildingId).pipe(
      switchMap(floorIds => {
        //API call to get the import job details for each floor
        return from(floorIds).pipe(
          mergeMap(floorId => {
            //Parallel api calls-> concurrent 10
            return this._getImportJob(partitionId, floorId);
          }, 10),
          filter(importJobData => importJobData !== null),
          toArray()
        );
      }),
      map(importJobsArr => {
        return this._getTransformedImportJobs(importJobsArr);
      })
    );
  }

  private _getImportJob(
    partitionId: string,
    floorId: string
  ): Observable<GetImportJobsResponseData> {
    const defaultPageSize = 100;
    return this.importJobsBeffService
      .getImportJobs(partitionId, floorId, defaultPageSize)
      .pipe(
        map(res => {
          return res?.data ? res?.data[0] : null;
        }),
        catchError(error => {
          if (error?.status !== 404) {
            const errMsg = error?.error?.errors?.[0]?.detail
              ? error?.error?.errors?.[0]?.detail
              : this.translate.instant('ERROR_MESSAGE.IMPORT_JOBS_LOAD_ERROR');
            this.toastService.queueToastNotification('warning', errMsg, '');
          }
          return of(null);
        })
      );
  }

  getPagedData(pageRequest: PageRequest): PagedData<ImportJobDetails> {
    let filteredElements;
    if (pageRequest.filteredElements) {
      filteredElements = pageRequest.filteredElements;
    } else {
      filteredElements = this.elements;
    }
    const pagedData = new PagedData<ImportJobDetails>();
    const page = new Page();

    page.pageNumber = pageRequest.offset;
    page.size = pageRequest.pageSize;
    page.totalElements = filteredElements.length;
    page.totalPages = page.totalElements / page.size;
    const start = page.pageNumber * page.size;
    const end = Math.min(start + page.size, page.totalElements);
    pagedData.data = filteredElements.slice(start, end);
    pagedData.page = page;
    return pagedData;
  }

  private _getTransformedImportJobs(
    importJobsArr: GetImportJobsResponseData[]
  ): ImportJobDetails[] {
    this.elements = importJobsArr.map(element => {
      const floorId =
        element?.attributes?.relationships?.representsFloor?.data?.id;
      const floorDetails = this._getFloorDetails(floorId);
      return {
        floorName: floorDetails?.attributes?.label,
        floorLevel:
          floorDetails?.attributes?.floorNumber !== undefined
            ? floorDetails?.attributes?.floorNumber.toString()
            : '0',
        uploadedDate: element?.attributes?.meta?.createdAt,
        expectedCompletionTime: element?.attributes?.meta?.expectedCompletionAt,
        type: element?.attributes?.artifacts?.[0]?.fileType,
        service:
          element?.attributes?.jobType ===
          GetImportJobsResponseDataAttributes.JobTypeEnum.Azure
            ? this.translate.instant('METHOD_SELECTION_MODAL.BASIC_METHOD_NAME')
            : this.translate.instant(
                'METHOD_SELECTION_MODAL.ADVANCED_METHOD_NAME'
              ),
        status: element?.attributes?.status,
        statusDescription: element?.attributes?.statusDescription,
        failureType: element?.attributes?.failureType,
        floorId: element?.attributes?.relationships?.representsFloor?.data?.id,
        importJobId: element?.id,
      };
    });
    return this.elements;
  }

  //Method to map the floor details with the Import Job using floorId
  private _getFloorDetails(floorId: string) {
    const floorDetails = this.floorData.find(ele => ele?.id === floorId);
    return floorDetails;
  }

  getAvailableFloorLvls() {
    return this.availableFloorLevels;
  }

  addFloorLevelForNewImport(floorLvl: number) {
    this.availableFloorLevels = [...this.availableFloorLevels, floorLvl];
  }
}
