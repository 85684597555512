import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SiToastNotificationService } from '@simpl/element-ng';
import {
  Observable,
  Subject,
  catchError,
  map,
  switchMap,
  tap,
  throwError,
} from 'rxjs';
import {
  AzureImportJobsRequestData,
  GetImportJobsResponseDataAttributes,
  ImportJobRequest,
  ImportJobsBeffService,
  ImportJobsResponse,
  ImportJobsResponseDataAttributes,
  StateBeffService,
} from '../apis/beff-api';
import {
  AzureLayersInfo,
  ImportJobsRequestData,
} from '../apis/beff-api/model/import-jobs-request-data';
import {
  LocationCreateRequest,
  LocationCreateResponse,
  LocationsService,
} from '../apis/sbs-location-api';
import { EXPECTED_PROCESSING_HRS } from '../components/constants/import.constants';
import { ImportJobDetails } from '../components/interfaces/file-upload-history.interface';
import { NewImportJobRequest } from '../components/interfaces/import-methods.interface';

@Injectable({
  providedIn: 'root',
})
export class ImportService {
  floorId = '';
  importJobId = '';
  newUploadJobDetails = new Subject<ImportJobDetails>();
  constructor(
    private http: HttpClient,
    public importJobsBeffService: ImportJobsBeffService,
    public locationsService: LocationsService,
    public stateBeffService: StateBeffService,
    private translate: TranslateService,
    private toastService: SiToastNotificationService
  ) {}

  startImport(importRequestDetails: NewImportJobRequest): Observable<any> {
    const uploadedFile = importRequestDetails.file?.file;
    let newFloorId = '';
    let newImportJob: ImportJobDetails;
    return this.createNewFloor(
      importRequestDetails.buildingId,
      importRequestDetails.floorName,
      importRequestDetails.floorLvl
    ).pipe(
      switchMap(floorId => {
        importRequestDetails.progressCount.next(20);
        newFloorId = floorId;
        return this.createNewImportJob(importRequestDetails, floorId).pipe(
          tap(res => {
            newImportJob = this.fetchNewImportJobDetails(
              res,
              importRequestDetails
            );
          })
        );
      }),
      switchMap(res => {
        importRequestDetails.progressCount.next(40);
        const fileUploadUrl = res?.data?.attributes?.fileUploadUrl;
        const contentType = 'image/x-dwg';
        this.importJobId = res?.data?.id;
        return this.uploadFileToAWSS3(fileUploadUrl, contentType, uploadedFile);
      }),
      switchMap(() => {
        importRequestDetails.progressCount.next(80);
        const importJobState = 'RUNNING';
        return this.stateBeffService
          .changeImportJobState(
            importRequestDetails.partitionId,
            newFloorId,
            this.importJobId,
            importJobState
          )
          .pipe(
            tap({
              next: () => this.broadcastNewlyAddedJob(newImportJob),
            })
          );
      }),
      catchError(error => {
        importRequestDetails.progressCount.next(0);
        if (newFloorId) {
          this.deleteNewlyCreatedFloor(newFloorId);
        }
        const errorMsg = error?.error?.errors?.[0]?.detail
          ? error?.error?.errors?.[0]?.detail
          : 'Failed To Upload File';
        this.toastService.queueToastNotification('warning', errorMsg, '');
        return throwError(() => error);
      })
    );
  }

  broadcastNewlyAddedJob(newImportJob: ImportJobDetails): void {
    this.newUploadJobDetails.next(newImportJob);
  }

  deleteNewlyCreatedFloor(newFloorId: string) {
    this.locationsService.deleteLocationById(newFloorId).subscribe();
  }

  /* eslint-disable  @typescript-eslint/no-explicit-any */
  uploadFileToAWSS3(
    fileUploadUrl: string,
    contentType: string,
    file: File
  ): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': contentType });
    return this.http.put(fileUploadUrl, file, {
      headers: headers,
      observe: 'response',
    });
  }

  createNewFloor(buildingId: string, floorName: string, floorLvl: number) {
    const locationCreateRequest: LocationCreateRequest = {
      data: {
        type: 'Floor',
        attributes: {
          label: floorName,
          floorNumber: floorLvl,
        },
        relationships: {
          isFloorOf: {
            data: {
              id: buildingId,
              type: 'Building',
            },
          },
        },
      },
    };

    return this.locationsService
      .createLocation(locationCreateRequest)
      .pipe(map((res: LocationCreateResponse) => res?.data?.id));
  }

  createNewImportJob(
    importRequestDetails: NewImportJobRequest,
    floorId: string
  ) {
    let importJobsRequest = this.createPayloadBasedOnJobType(
      importRequestDetails.file?.fileName,
      importRequestDetails.file?.fileName.split('.')[1],
      importRequestDetails.importJobType,
      importRequestDetails.buildingId,
      floorId,
      importRequestDetails.layers
    );

    return this.importJobsBeffService.postFloorplans(
      importRequestDetails.partitionId,
      floorId,
      importJobsRequest
    );
  }

  fetchNewImportJobDetails(
    res: ImportJobsResponse,
    newImportJobRequest: NewImportJobRequest
  ): ImportJobDetails {
    return {
      floorName: newImportJobRequest.floorName,
      floorLevel: newImportJobRequest.floorLvl.toString(),
      type: newImportJobRequest.file?.fileName.split('.')[1]
        ? newImportJobRequest.file?.fileName.split('.')[1]
        : '',
      service:
        newImportJobRequest.importJobType ===
        ImportJobsResponseDataAttributes.JobTypeEnum.Azure
          ? this.translate.instant('METHOD_SELECTION_MODAL.BASIC_METHOD_NAME')
          : this.translate.instant(
              'METHOD_SELECTION_MODAL.ADVANCED_METHOD_NAME'
            ),
      status: 'RUNNING',
      uploadedDate: res?.data?.attributes?.meta?.createdAt,
      statusDescription: '',
      expectedCompletionTime: this.calculateExpectedTime(
        newImportJobRequest.importJobType,
        res?.data?.attributes?.meta?.createdAt
      ),
      floorId: res?.data?.attributes?.relationships?.representsFloor?.data.id,
      importJobId: res?.data?.id,
    };
  }
  calculateExpectedTime(importJobType: string, uploadedDate: string): string {
    const expectedDate = new Date(uploadedDate);
    const options: Intl.DateTimeFormatOptions = {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true,
    };
    if (
      importJobType === GetImportJobsResponseDataAttributes.JobTypeEnum.Azure
    ) {
      expectedDate.setHours(
        expectedDate.getHours() + EXPECTED_PROCESSING_HRS.AZURE_N
      );
      return expectedDate.toLocaleDateString('en-US', options);
    } else {
      expectedDate.setHours(
        expectedDate.getHours() + EXPECTED_PROCESSING_HRS.POINTER_N
      );
      return expectedDate.toLocaleDateString('en-US', options);
    }
  }

  createPayloadBasedOnJobType(
    fileName: string,
    fileType: string,
    importJobType: ImportJobsRequestData.JobTypeEnum,
    buildingId: string,
    floorId: string,
    layers: AzureLayersInfo
  ) {
    let importRequest: ImportJobRequest = {
      data: {
        fileName: fileName,
        fileType: fileType,
        jobType: importJobType,
        relationships: {
          isPartOfBuilding: {
            data: {
              type: 'Building',
              id: buildingId,
            },
          },
          representsFloor: {
            data: {
              type: 'Floor',
              id: floorId,
            },
          },
        },
      },
    };
    if (importJobType === 'AZURE') {
      importRequest.data = {
        ...importRequest.data,
        dwgLayerUnit: layers.unitLayer,
        dwgExteriorLayer: layers.exteriorLayer,
      } as AzureImportJobsRequestData;
    }
    return importRequest;
  }

  /**
   * this method is used to tell if a row should be shown after a search query is appliled
   * @param floor floor details in table
   * @param criteria criteria with which to search in table
   * @returns
   */
  filterFunction(floor: any, criteria: any): boolean {
    let count;
    let returnStatus;
    for (count = 0; count < criteria.length; count++) {
      switch (criteria[count].name) {
        case this.translate.instant('SEARCH_CRITERIA.FLOOR'): {
          returnStatus = this.filterByValue(
            floor,
            criteria[count].value,
            'floorName'
          );
          break;
        }
        case this.translate.instant('SEARCH_CRITERIA.FLOOR_LEVEL'): {
          returnStatus = this.filterByValue(
            floor,
            criteria[count].value,
            'floorLevel'
          );
          break;
        }
        case this.translate.instant('SEARCH_CRITERIA.UPLOADED_DATE'): {
          returnStatus = this.filterByValue(
            floor,
            criteria[count].value,
            'uploadedDate'
          );
          break;
        }
        case this.translate.instant(
          'SEARCH_CRITERIA.EXPECTED_COMPLETION_TIME'
        ): {
          returnStatus = this.filterByValue(
            floor,
            criteria[count].value,
            'expectedCompletionTime'
          );
          break;
        }
        case this.translate.instant('SEARCH_CRITERIA.TYPE'): {
          returnStatus = this.filterByValue(
            floor,
            criteria[count].value,
            'type'
          );
          break;
        }
        case this.translate.instant('SEARCH_CRITERIA.SERVICE'): {
          returnStatus = this.filterByValue(
            floor,
            criteria[count].value,
            'service'
          );
          break;
        }
        case this.translate.instant('SEARCH_CRITERIA.STATUS'): {
          returnStatus = this.filterByValue(
            floor,
            criteria[count].value,
            'status'
          );
          break;
        }
      }
      if (!returnStatus) {
        return false;
      }
    }
    return true;
  }

  filterByValue(floor: any, value: string, attribute: string) {
    return (
      floor[attribute]
        .toLowerCase()
        .indexOf(value.toLocaleLowerCase().trim()) !== -1
    );
  }

  isFloorLvlValid(event: any, availableFLoorLevels: number[]) {
    if (
      event.floorLvl.trim().length &&
      !availableFLoorLevels.includes(Number(event.floorLvl))
    ) {
      return { floorLvl: event.floorLvl, valid: true, isNumberPresent: false };
    } else if (
      event.floorLvl.trim().length &&
      availableFLoorLevels.includes(Number(event.floorLvl))
    ) {
      return { floorLvl: event.floorLvl, valid: false, isNumberPresent: true };
    } else {
      return { floorLvl: '', valid: false, isNumberPresent: false };
    }
  }

  isFloorNameValid(event: any) {
    if (event.floorName.trim().length) {
      return { floorName: event.floorName, valid: true };
    } else {
      return { floorName: '', valid: false };
    }
  }
}
