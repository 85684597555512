import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SiModalService, SiToastNotificationService } from '@simpl/element-ng';
import { Subscription, catchError, combineLatest, throwError } from 'rxjs';

import {
  HistoryTablePermissions,
  ImportJobDetails,
} from './components/interfaces/file-upload-history.interface';
import { ImportSelectorComponent } from './components/modals/import-selector/import-selector.component';
import { FileUploadHistoryService } from './services/file-upload-history.service';
import { ImportService } from './services/import.service';
import {
  PermissionIds,
  PermissionServiceService,
} from './services/permission-service.service';

@Component({
  selector: 'app-plan-import',
  templateUrl: './plan-import.component.html',
  styleUrls: ['./plan-import.component.scss'],
})
export class PlanImportComponent implements OnInit, OnDestroy, OnChanges {
  /**
   * Id of our organization
   */
  @Input({ required: true }) partitionId!: string;

  /**
   * Building we are working on. Will load structure and objects for this building
   */
  @Input({ required: true }) buildingId!: string;

  /**
   * Customer connected to the partition. Need this to fetch permissions
   */
  @Input({ required: true }) customerId!: string;
  /**
   * Api host for beff api
   */
  @Input({ required: true }) beffApiHost!: string;

  /**
   * Api host for structure service
   */
  @Input({ required: true }) structureApiHost!: string;

  /**
   * Api host for aods service
   */
  @Input({ required: true }) aodsHost!: string;

  isLoading = false;
  sub$: Subscription = new Subscription();
  jobImportDetails: ImportJobDetails[] | null = null;
  canStartImport = false;
  canCreateAdvanced = false;
  canCreateBasic = false;
  permissionForHistoryTable: HistoryTablePermissions = {
    canCreateFloorplan: false,
  };

  constructor(
    private modalService: SiModalService,
    private translate: TranslateService,
    private importService: ImportService,
    private fileUploadHistoryService: FileUploadHistoryService,
    private toastService: SiToastNotificationService,
    private permissionService: PermissionServiceService
  ) {}
  moreDetails: any = '';

  ngOnInit() {
    // configure api services
    if (this.structureApiHost != null) {
      this.importService.locationsService.configuration.basePath = `${this.structureApiHost}/partitions/${this.partitionId}`;
      this.fileUploadHistoryService.locationsService.configuration.basePath = `${this.structureApiHost}/partitions/${this.partitionId}`;
    }
    if (this.beffApiHost != null) {
      this.importService.importJobsBeffService.configuration.basePath =
        this.beffApiHost;
      this.importService.stateBeffService.configuration.basePath =
        this.beffApiHost;
    }
    if (this.aodsHost != null) {
      this.permissionService.configureHost(this.aodsHost);
    }

    this._loadPermissions(this.customerId, this.partitionId);
    this.loadImportJobDetails();
    this._getPermissionDetails();
  }

  private _getPermissionDetails() {
    this.sub$.add(
      combineLatest([
        this.permissionService.hasPermission(PermissionIds.CREATE_BASIC),
        this.permissionService.hasPermission(PermissionIds.CREATE_ADVANCED),
        this.permissionService.hasPermission(PermissionIds.CREATE),
      ]).subscribe(
        ([canCreateBasic, canCreateAdvanced, canCreateFloorPlan]) => {
          this.canCreateBasic = canCreateBasic;
          this.canCreateAdvanced = canCreateAdvanced;
          this.permissionForHistoryTable.canCreateFloorplan =
            canCreateFloorPlan;
        }
      )
    );
  }

  private _loadPermissions(customerId: string, partitionId: string) {
    if (customerId && partitionId) {
      this.permissionService.loadPermissions(customerId, partitionId);
    }
  }

  ngOnDestroy() {
    this.sub$.unsubscribe();
  }

  startNewImport() {
    const permissionDetails = {
      AZR: this.canCreateBasic,
      PTR: this.canCreateAdvanced,
    };

    this.modalService.show(ImportSelectorComponent, {
      class: 'method-selector-modal',
      keyboard: false,
      initialState: {
        partitionId: this.partitionId,
        buildingId: this.buildingId,
        permissionDetails: permissionDetails,
      },
    });
  }

  loadImportJobDetails() {
    this.isLoading = true;
    if (!this.partitionId || !this.buildingId) {
      this.isLoading = false;
      return;
    }
    if (this.partitionId && this.buildingId) {
      this.sub$.add(
        this.fileUploadHistoryService
          .getAllImportJobs(this.partitionId, this.buildingId)
          .pipe(
            catchError(error => {
              if (error.status === 500) {
                this.toastService.queueToastNotification(
                  'warning',
                  this.translate.instant(
                    'ERROR_MESSAGE.IMPORT_JOBS_LOAD_ERROR'
                  ),
                  ''
                );
              } else {
                const errMsg = error?.error?.errors?.[0]?.detail
                  ? error?.error?.errors?.[0]?.detail
                  : this.translate.instant(
                      'ERROR_MESSAGE.IMPORT_JOBS_LOAD_ERROR'
                    );
                this.toastService.queueToastNotification('warning', errMsg, '');
              }
              this.canStartImport = false;
              this.isLoading = false;
              this.jobImportDetails = [];
              return throwError(() => error);
            })
          )
          .subscribe(res => {
            if (res) {
              this.canStartImport = true;
              this.jobImportDetails = res;
              this.isLoading = false;
            }
          })
      );
    }
  }

  onRefresh() {
    this.loadImportJobDetails();
  }

  //Method to load import job details on partitionId, buildingId change
  ngOnChanges(changes: SimpleChanges) {
    if (
      !changes?.['buildingId']?.firstChange &&
      !changes?.['partitionId']?.firstChange &&
      !changes?.['customerId']?.firstChange
    ) {
      this.loadImportJobDetails();
      this._loadPermissions(this.customerId, this.partitionId);
    }
  }
}
