/**
 * BX Location Service v2
 * Location Service implementation is following: - Location model: [BTLO 1.1](https://bt.schema.siemens.io/bt/shared/btlo/version/1.1) - Zones model: [BTZF 2.2](https://bt.schema.siemens.io/bt/shared/btzf/version/2.2) 
 *
 * The version of the OpenAPI document: 2.5.12
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AddressMeta } from './addressMeta';
import { CampusPartAttributes } from './campusPartAttributes';


/**
 * Represents a CampusPart
 */
export interface CampusPart { 
    /**
     * ID of this object
     */
    id: string;
    /**
     * Type of this object
     */
    type: CampusPart.TypeEnum;
    attributes: CampusPartAttributes;
    relationships: object;
    meta?: AddressMeta;
}
export namespace CampusPart {
    export type TypeEnum = 'CampusPart';
    export const TypeEnum = {
        CampusPart: 'CampusPart' as TypeEnum
    };
}


