import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { AodsConfiguration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { PermissionsAodsService } from './api/permissions-service';
import { TokenAodsService } from './api/token-service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: []
})
export class AodsApiModule {
    public static forRoot(configurationFactory: () => AodsConfiguration): ModuleWithProviders<AodsApiModule> {
        return {
            ngModule: AodsApiModule,
            providers: [ { provide: AodsConfiguration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: AodsApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('AodsApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
