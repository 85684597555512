import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-floor-info-input',
  templateUrl: './floor-info-input.component.html',
  styleUrls: ['./floor-info-input.component.scss'],
})
export class FloorInfoInputComponent {
  floorName = '';
  floorLevel = '';

  @Output() isFloorDetailValid: EventEmitter<any> = new EventEmitter();
  @Input() isLevelPresent: boolean;

  checkFloorNameValidity(event: any) {
    this.isFloorDetailValid.emit({ floorName: event.target.value });
  }

  checkFloorLvlValidity(event: any) {
    this.isFloorDetailValid.emit({
      floorLvl: event.target.value.toString(),
    });
  }
}
