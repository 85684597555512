import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { SessionService } from './session.service';
import { AZURE_AWS_DOMAIN } from '../components/constants/import.constants';

// TODO: remove this service once horizon backend available and use horizon token instead
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private sessionService: SessionService) {}
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    // Skip authenticated services
    // if (authenticatedServices.some(x => request.url.includes(x))) {
    //   return next.handle(request);
    // }

    const awsDomain = AZURE_AWS_DOMAIN;
    if (request.url.includes(awsDomain)) {
      if (request.headers.get('Authorization')) {
        request = request.clone({
          headers: request.headers.delete('Authorization'),
        });
      }
      return next.handle(request);
    }

    const params = new URLSearchParams(window.location.search);
    // use token from session, fall back to url param

    const token =
      this.sessionService.getViewerToken() || params.get('authToken');

    // If we have an auth token, we append it to our new headers
    if (token) {
      // clone request with new headers
      request = request.clone({
        setHeaders: {
          authorization: `Bearer ${token}`,
        },
      });
    }

    return next.handle(request);
  }
}
