export const IMPORT_METHODS = [
  {
    ID: 'AZR',
    METHOD_NAME: 'METHOD_SELECTION_MODAL.BASIC_METHOD_NAME',
    METHOD_DESCRIPTION: 'METHOD_SELECTION_MODAL.BASIC_METHOD_DESC',
    MORE_DETAILS:
      'https://sid.siemens.com/r/A6V12481797/19514268299_21927004683__en-US_22125550091',
    enabled: false,
  },
  {
    ID: 'PTR',
    METHOD_NAME: 'METHOD_SELECTION_MODAL.ADVANCED_METHOD_NAME',
    METHOD_DESCRIPTION: 'METHOD_SELECTION_MODAL.ADVANCED_METHOD_DESC',
    MORE_DETAILS: '',
    enabled: false,
  },
];

export const TABLE_PROPS = {
  FLOOR_NAME: 'floorName',
  FLOOR_LEVEL: 'floorLevel',
  UPLOADED_DT: 'uploadedDate',
  EXPECTED_TIME: 'expectedCompletionTime',
  TYPE: 'type',
  SERVICE: 'service',
  STATUS: 'status',
};

export type IMPORT_SERVICES_ENUM = 'AZURE' | 'POINTR';

export const EXPECTED_PROCESSING_HRS = {
  AZURE_N: 2,
  POINTER_N: 48,
  AZURE_S: '2 Hours',
  POINTER_S: '48 Hours',
};

export const ACCEPTED_FILE_TYPES = {
  AZURE: '.dwg,.DWG,image/vnd.dwg',
  POINTR: '.dwg,.DWG,.pdf,.dxf',
};

export const AZURE_AWS_DOMAIN = '.amazonaws.com/';
