import { Injectable } from '@angular/core';

export interface AccountInfo {
  title?: string;
  image?: string;
}

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  constructor() {
    // This is intentional
  }

  setViewerToken(token: string | null): void {
    if (token) {
      localStorage.setItem('viewer_auth_token', token);
    }
  }

  getViewerToken(): string | null {
    return sessionStorage.getItem('access_token');
  }
}
