/**
 * BX Location Service v2
 * Location Service implementation is following: - Location model: [BTLO 1.1](https://bt.schema.siemens.io/bt/shared/btlo/version/1.1) - Zones model: [BTZF 2.2](https://bt.schema.siemens.io/bt/shared/btzf/version/2.2) 
 *
 * The version of the OpenAPI document: 2.5.12
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Represents an 2D- or 3D-Point in space, according to RFC7946
 */
export interface BuildingAttributesGeoLocationGeometry { 
    type: BuildingAttributesGeoLocationGeometry.TypeEnum;
    /**
     * Contains a position which is an array of numbers.  There MUST be two or more elements.  The first two elements are longitude and latitude, or easting and northing, precisely in that order and using decimal numbers.  Altitude or elevation MAY be included as an optional third element. I.e. \'[longitude,latitude]\'
     */
    coordinates: Array<number>;
}
export namespace BuildingAttributesGeoLocationGeometry {
    export type TypeEnum = 'Point';
    export const TypeEnum = {
        Point: 'Point' as TypeEnum
    };
}


