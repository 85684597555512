// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.badge {
  margin-inline: 0;
}

.cursor {
  cursor: pointer;
}

.card {
  block-size: calc(100vh - 172px);
  inline-size: 100%;
  min-block-size: 300px;
}

.section-title {
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
}

.sub-heading {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
}

.details {
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
}

.element-set-icon {
  color: var(--element-ui-3);
  font-size: 5rem;
}

.table-container-sizing {
  block-size: calc(100vh - 281px);
  min-block-size: 200px;
  overflow: visible;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
