// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.section-title {
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
}

.sub-heading {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
}

.details {
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
}

.plan-import-container {
  display: flex;
  flex-direction: column;
}

.import-actions {
  display: flex;
  justify-content: flex-end;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
