import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { PermissionsAodsService } from '../apis/aods-api';
import { SiToastNotificationService } from '@simpl/element-ng';

export enum PermissionIds {
  READ = 'geo.floorplan.read',
  CREATE = 'geo.floorplan.create',
  UPDATE = 'geo.floorplan.update',
  DELETE = 'geo.floorplan.delete',
  CREATE_ADVANCED = 'pim.import-job-dwg2.create',
  CREATE_BASIC = 'pim.import-job-dwg1.create',
}

@Injectable({
  providedIn: 'root',
})
export class PermissionServiceService {
  private _permissions = new BehaviorSubject<string[]>([]);

  constructor(
    private permissionsService: PermissionsAodsService,
    private toastService: SiToastNotificationService
  ) {}

  configureHost(aodsHost: string): void {
    // strip api suffix
    this.permissionsService.configuration.basePath = aodsHost.split('/api')[0];
  }

  loadPermissions(customerId: string, partitionId: string): void {
    this._permissions.next([]);
    // fetch and try to find permissions for partition
    this.permissionsService
      .getPermissionsAllV2AgainstCustomerId(customerId)
      .subscribe({
        next: p => {
          if (p.data.attributes.length) {
            const partition = p.data.attributes[0].partitions.find(
              p => p.targetId === partitionId
            );
            if (partition != null) {
              this._permissions.next(partition.permissions);
            }
          }
        },
        error: () =>
          this.toastService.queueToastNotification(
            'warning',
            'Could not load permissions',
            ''
          ),
      });
  }

  hasPermission(permission: string): Observable<boolean> {
    return this._permissions.pipe(map(p => p.includes(permission)));
  }
}
