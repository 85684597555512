/**
 * Plan Import App BFF
 * Plan Import App BFF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FloorplanRelationships } from './floorplan-relationships';


export interface AzureImportJobsRequestData { 
    /**
     * File name
     */
    fileName: string;
    /**
     * type of file
     */
    fileType: string;
    /**
     * Type of import method
     */
    jobType: AzureImportJobsRequestData.JobTypeEnum;
    /**
     * Comma separated exterior layers name in DWG. Default value is \"OUTLINE\". Used formultipart/form-data type of requests only.
     */
    dwgExteriorLayer: string;
    /**
     * Comma separated exterior layers name in DWG. Default value is \"UNITS\". Used for multipart/form-data type of requests only.
     */
    dwgLayerUnit: string;
    relationships: FloorplanRelationships;
}
export namespace AzureImportJobsRequestData {
    export type JobTypeEnum = 'AZURE' | 'POINTR';
    export const JobTypeEnum = {
        Azure: 'AZURE' as JobTypeEnum,
        Pointr: 'POINTR' as JobTypeEnum
    };
}


