/**
 * Authorization Decision Service
 *  # Authorization A valid authorization header with a machine bearer token has to be provided. The bearer token is expected as JSON Web Token (JWT). You may use jwt.io to decode any token you have.  # AoDS V2 AoDS V2 endpoints are disabled by default. (Feature Toggle)  # Further information Please contact the team42 for any questions and please report issues and possible improvements. 
 *
 * The version of the OpenAPI document: 1.0.0-oas3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { ErrorsResponseRFC6749 } from '../model/models';
import { ErrorsResponseV1 } from '../model/models';
import { TokenKeyResponseV1 } from '../model/models';
import { TokenResponseV1 } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { AodsConfiguration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class TokenAodsService {

    protected basePath = 'https://aods.horizondev.cloud';
    public defaultHeaders = new HttpHeaders();
    public configuration = new AodsConfiguration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: AodsConfiguration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }

    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * returns internal token.
     * @param grantType only support for urn:ietf:params:oauth:grant-type:token-exchange
     * @param subjectToken JWT token from REST call initiator
     * @param subjectTokenType only support for urn:ietf:params:oauth:token-type:jwt
     * @param audience targeted service
     * @param resource targeted partition
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createInterrnalTokenV1(grantType: string, subjectToken: string, subjectTokenType: string, audience: string, resource: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/json;charset&#x3D;UTF-8'}): Observable<TokenResponseV1>;
    public createInterrnalTokenV1(grantType: string, subjectToken: string, subjectTokenType: string, audience: string, resource: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/json;charset&#x3D;UTF-8'}): Observable<HttpResponse<TokenResponseV1>>;
    public createInterrnalTokenV1(grantType: string, subjectToken: string, subjectTokenType: string, audience: string, resource: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/json;charset&#x3D;UTF-8'}): Observable<HttpEvent<TokenResponseV1>>;
    public createInterrnalTokenV1(grantType: string, subjectToken: string, subjectTokenType: string, audience: string, resource: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json' | 'application/json;charset&#x3D;UTF-8'}): Observable<any> {
        if (grantType === null || grantType === undefined) {
            throw new Error('Required parameter grantType was null or undefined when calling createInterrnalTokenV1.');
        }
        if (subjectToken === null || subjectToken === undefined) {
            throw new Error('Required parameter subjectToken was null or undefined when calling createInterrnalTokenV1.');
        }
        if (subjectTokenType === null || subjectTokenType === undefined) {
            throw new Error('Required parameter subjectTokenType was null or undefined when calling createInterrnalTokenV1.');
        }
        if (audience === null || audience === undefined) {
            throw new Error('Required parameter audience was null or undefined when calling createInterrnalTokenV1.');
        }
        if (resource === null || resource === undefined) {
            throw new Error('Required parameter resource was null or undefined when calling createInterrnalTokenV1.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (BearerAuth) required
        credential = this.configuration.lookupCredential('BearerAuth');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json',
                'application/json;charset=UTF-8'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: this.encoder});
        }

        if (grantType !== undefined) {
            formParams = formParams.append('grant_type', <any>grantType) as any || formParams;
        }
        if (subjectToken !== undefined) {
            formParams = formParams.append('subject_token', <any>subjectToken) as any || formParams;
        }
        if (subjectTokenType !== undefined) {
            formParams = formParams.append('subject_token_type', <any>subjectTokenType) as any || formParams;
        }
        if (audience !== undefined) {
            formParams = formParams.append('audience', <any>audience) as any || formParams;
        }
        if (resource !== undefined) {
            formParams = formParams.append('resource', <any>resource) as any || formParams;
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<TokenResponseV1>(`${this.configuration.basePath}/api/v1/token`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * returns internal token.
     * @param grantType only support for urn:ietf:params:oauth:grant-type:token-exchange
     * @param subjectToken JWT token from REST call initiator
     * @param subjectTokenType only support for urn:ietf:params:oauth:token-type:jwt
     * @param audience targeted service
     * @param resource targeted partition
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createInterrnalTokenV2(grantType: string, subjectToken: string, subjectTokenType: string, audience: string, resource: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/json;charset&#x3D;UTF-8'}): Observable<TokenResponseV1>;
    public createInterrnalTokenV2(grantType: string, subjectToken: string, subjectTokenType: string, audience: string, resource: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/json;charset&#x3D;UTF-8'}): Observable<HttpResponse<TokenResponseV1>>;
    public createInterrnalTokenV2(grantType: string, subjectToken: string, subjectTokenType: string, audience: string, resource: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/json;charset&#x3D;UTF-8'}): Observable<HttpEvent<TokenResponseV1>>;
    public createInterrnalTokenV2(grantType: string, subjectToken: string, subjectTokenType: string, audience: string, resource: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json' | 'application/json;charset&#x3D;UTF-8'}): Observable<any> {
        if (grantType === null || grantType === undefined) {
            throw new Error('Required parameter grantType was null or undefined when calling createInterrnalTokenV2.');
        }
        if (subjectToken === null || subjectToken === undefined) {
            throw new Error('Required parameter subjectToken was null or undefined when calling createInterrnalTokenV2.');
        }
        if (subjectTokenType === null || subjectTokenType === undefined) {
            throw new Error('Required parameter subjectTokenType was null or undefined when calling createInterrnalTokenV2.');
        }
        if (audience === null || audience === undefined) {
            throw new Error('Required parameter audience was null or undefined when calling createInterrnalTokenV2.');
        }
        if (resource === null || resource === undefined) {
            throw new Error('Required parameter resource was null or undefined when calling createInterrnalTokenV2.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (BearerAuth) required
        credential = this.configuration.lookupCredential('BearerAuth');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json',
                'application/json;charset=UTF-8'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: this.encoder});
        }

        if (grantType !== undefined) {
            formParams = formParams.append('grant_type', <any>grantType) as any || formParams;
        }
        if (subjectToken !== undefined) {
            formParams = formParams.append('subject_token', <any>subjectToken) as any || formParams;
        }
        if (subjectTokenType !== undefined) {
            formParams = formParams.append('subject_token_type', <any>subjectTokenType) as any || formParams;
        }
        if (audience !== undefined) {
            formParams = formParams.append('audience', <any>audience) as any || formParams;
        }
        if (resource !== undefined) {
            formParams = formParams.append('resource', <any>resource) as any || formParams;
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<TokenResponseV1>(`${this.configuration.basePath}/api/v2/token`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * returns public key(s) associated with aods for internal token validation
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPublicKeyForInternalTokenV1(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.api+json' | 'application/json;charset&#x3D;UTF-8'}): Observable<TokenKeyResponseV1>;
    public getPublicKeyForInternalTokenV1(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.api+json' | 'application/json;charset&#x3D;UTF-8'}): Observable<HttpResponse<TokenKeyResponseV1>>;
    public getPublicKeyForInternalTokenV1(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.api+json' | 'application/json;charset&#x3D;UTF-8'}): Observable<HttpEvent<TokenKeyResponseV1>>;
    public getPublicKeyForInternalTokenV1(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/vnd.api+json' | 'application/json;charset&#x3D;UTF-8'}): Observable<any> {

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/vnd.api+json',
                'application/json;charset=UTF-8'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<TokenKeyResponseV1>(`${this.configuration.basePath}/api/v1/token-keys`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * returns public key(s) associated with aods for internal token validation
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPublicKeyForInternalTokenV2(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.api+json' | 'application/json;charset&#x3D;UTF-8'}): Observable<TokenKeyResponseV1>;
    public getPublicKeyForInternalTokenV2(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.api+json' | 'application/json;charset&#x3D;UTF-8'}): Observable<HttpResponse<TokenKeyResponseV1>>;
    public getPublicKeyForInternalTokenV2(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.api+json' | 'application/json;charset&#x3D;UTF-8'}): Observable<HttpEvent<TokenKeyResponseV1>>;
    public getPublicKeyForInternalTokenV2(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/vnd.api+json' | 'application/json;charset&#x3D;UTF-8'}): Observable<any> {

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/vnd.api+json',
                'application/json;charset=UTF-8'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<TokenKeyResponseV1>(`${this.configuration.basePath}/api/v2/token-keys`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
