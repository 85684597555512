// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title {
  font-family: Siemens Sans;
  font-size: 24px;
  font-weight: 900;
  line-height: 32px;
  text-align: left;
}

.import-selector {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.method-desc {
  font-family: Siemens Sans;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.150000006px;
  line-height: 16px;
  padding-top: 0.4rem;
  text-align: left;
}

.method-name {
  font-family: Siemens Sans;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.15px;
  line-height: 16px;
  text-align: left;
}

.radio-details {
  display: flex;
  flex-direction: column;
}

.method-selector-btns {
  display: flex;
  justify-content: end;
}

.method-more-details {
  padding-top: 1rem;
}

.radio-container {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}

.method-selector-modal {
  width: 34rem;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
