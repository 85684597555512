/**
 * BX Location Service v2
 * Location Service implementation is following: - Location model: [BTLO 1.1](https://bt.schema.siemens.io/bt/shared/btlo/version/1.1) - Zones model: [BTZF 2.2](https://bt.schema.siemens.io/bt/shared/btzf/version/2.2) 
 *
 * The version of the OpenAPI document: 2.5.12
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AddressAttributes { 
    locality?: string | null;
    /**
     * A country code according to \"ISO 3166-1 alpha-3\"
     */
    countryCode: string;
    /**
     * A country name (in English)
     */
    readonly countryName: string | null;
    /**
     * A 2-character continent code
     */
    readonly continentCode: AddressAttributes.ContinentCodeEnum;
    /**
     * A continent name (in English)
     */
    readonly continentName: AddressAttributes.ContinentNameEnum | null;
    region?: string | null;
    postalCode?: string | null;
    street?: string | null;
    readonly formattedAddress?: string | null;
}
export namespace AddressAttributes {
    export type ContinentCodeEnum = 'AF' | 'AN' | 'AS' | 'EU' | 'NA' | 'OC' | 'SA';
    export const ContinentCodeEnum = {
        Af: 'AF' as ContinentCodeEnum,
        An: 'AN' as ContinentCodeEnum,
        As: 'AS' as ContinentCodeEnum,
        Eu: 'EU' as ContinentCodeEnum,
        Na: 'NA' as ContinentCodeEnum,
        Oc: 'OC' as ContinentCodeEnum,
        Sa: 'SA' as ContinentCodeEnum
    };
    export type ContinentNameEnum = 'Africa' | 'Antarctica' | 'Asia' | 'Europe' | 'North America' | 'Oceania' | 'South America' | 'null';
    export const ContinentNameEnum = {
        Africa: 'Africa' as ContinentNameEnum,
        Antarctica: 'Antarctica' as ContinentNameEnum,
        Asia: 'Asia' as ContinentNameEnum,
        Europe: 'Europe' as ContinentNameEnum,
        NorthAmerica: 'North America' as ContinentNameEnum,
        Oceania: 'Oceania' as ContinentNameEnum,
        SouthAmerica: 'South America' as ContinentNameEnum,
        Null: 'null' as ContinentNameEnum
    };
}


