/**
 * BX Location Service v2
 * Location Service implementation is following: - Location model: [BTLO 1.1](https://bt.schema.siemens.io/bt/shared/btlo/version/1.1) - Zones model: [BTZF 2.2](https://bt.schema.siemens.io/bt/shared/btzf/version/2.2) 
 *
 * The version of the OpenAPI document: 2.5.12
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { LocationCreateRequest } from '../model/models';
import { LocationCreateResponse } from '../model/models';
import { LocationListResponse } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { SbsLocationConfiguration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class LocationsService {

    protected basePath = 'https://locations.horizoncloud.io/v2/partitions/REQUIRED';
    public defaultHeaders = new HttpHeaders();
    public configuration = new SbsLocationConfiguration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: SbsLocationConfiguration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Create Location
     * For a location to be created with a &#x60;hasPostalAddress&#x60; relationship to an &#x60;Address&#x60;, the address needs to either 1) already exist or 2) be passed in the &#x60;included&#x60;-array of the playload, in which case it needs a client generated ID which is referenced from the &#x60;hasPostalAddress&#x60;-relationship.  If a payload contains an &#x60;Address&#x60; in the &#x60;included&#x60;-array which is not created as part of the request, i.e. it is not refered to, then the server responds with an error. 
     * @param locationCreateRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createLocation(locationCreateRequest: LocationCreateRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.api+json'}): Observable<LocationCreateResponse>;
    public createLocation(locationCreateRequest: LocationCreateRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.api+json'}): Observable<HttpResponse<LocationCreateResponse>>;
    public createLocation(locationCreateRequest: LocationCreateRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.api+json'}): Observable<HttpEvent<LocationCreateResponse>>;
    public createLocation(locationCreateRequest: LocationCreateRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/vnd.api+json'}): Observable<any> {
        if (locationCreateRequest === null || locationCreateRequest === undefined) {
            throw new Error('Required parameter locationCreateRequest was null or undefined when calling createLocation.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (service_auth) required
        credential = this.configuration.lookupCredential('service_auth');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/vnd.api+json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/vnd.api+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<LocationCreateResponse>(`${this.configuration.basePath}/locations`,
            locationCreateRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete Location
     * Deletes the Location
     * @param locationId ID of the Location
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteLocationById(locationId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.api+json'}): Observable<any>;
    public deleteLocationById(locationId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.api+json'}): Observable<HttpResponse<any>>;
    public deleteLocationById(locationId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.api+json'}): Observable<HttpEvent<any>>;
    public deleteLocationById(locationId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/vnd.api+json'}): Observable<any> {
        if (locationId === null || locationId === undefined) {
            throw new Error('Required parameter locationId was null or undefined when calling deleteLocationById.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (service_auth) required
        credential = this.configuration.lookupCredential('service_auth');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/vnd.api+json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/locations/${encodeURIComponent(String(locationId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * List Locations
     * List Locations
     * @param pageSize The numbers of items to return
     * @param pageNumber The page number (how many pages to skip)
     * @param include Includes the hasPostalAddress relationship
     * @param filterType Filter by \&#39;type\&#39;
     * @param filterLabel Filter by \&#39;label\&#39;
     * @param filterExternalId Filter by \&#39;externalId\&#39;
     * @param filterTagsTagKey Filter by \&#39;tags\&#39;
     * @param filterHasPostalAddressDataId Filter by \&#39;id\&#39; of hasPostalAddress relationship
     * @param filterIsPartOfDataId Filter by \&#39;id\&#39; of isPartOf relationship
     * @param filterIsPartOfDataType Filter by \&#39;type\&#39; of isPartOf relationship
     * @param filterBuildingType Filter by \&#39;buildingType\&#39;
     * @param filterUsableAreaValue Filter by \&#39;value\&#39;
     * @param filterHasPostalAddressCountryCode Filter by \&#39;hasPostalAddress.countryCode\&#39;
     * @param filterHasPostalAddressContinentCode Filter by \&#39;hasPostalAddress.continentCode\&#39;
     * @param fields Add optional fields to response. Valid options: \&#39;hasAssets\&#39; 
     * @param fieldsAddress Add optional fields to response if hasPostalAddress is in included array. Valid options: \&#39;formattedAddress\&#39; 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listLocation(pageSize?: number, pageNumber?: number, include?: 'hasPostalAddress', filterType?: 'Campus' | 'CampusPart' | 'Building' | 'BuildingPart' | 'Outside' | 'Floor' | 'FloorArea' | 'MultifloorArea' | 'Room' | 'RoomSegment', filterLabel?: any, filterExternalId?: any, filterTagsTagKey?: any, filterHasPostalAddressDataId?: any, filterIsPartOfDataId?: any, filterIsPartOfDataType?: 'Campus' | 'CampusPart' | 'Building' | 'BuildingPart' | 'Outside' | 'Floor' | 'MultifloorArea' | 'Area' | 'Room' | 'RoomSegment', filterBuildingType?: any, filterUsableAreaValue?: any, filterHasPostalAddressCountryCode?: string, filterHasPostalAddressContinentCode?: string, fields?: string, fieldsAddress?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.api+json'}): Observable<LocationListResponse>;
    public listLocation(pageSize?: number, pageNumber?: number, include?: 'hasPostalAddress', filterType?: 'Campus' | 'CampusPart' | 'Building' | 'BuildingPart' | 'Outside' | 'Floor' | 'FloorArea' | 'MultifloorArea' | 'Room' | 'RoomSegment', filterLabel?: any, filterExternalId?: any, filterTagsTagKey?: any, filterHasPostalAddressDataId?: any, filterIsPartOfDataId?: any, filterIsPartOfDataType?: 'Campus' | 'CampusPart' | 'Building' | 'BuildingPart' | 'Outside' | 'Floor' | 'MultifloorArea' | 'Area' | 'Room' | 'RoomSegment', filterBuildingType?: any, filterUsableAreaValue?: any, filterHasPostalAddressCountryCode?: string, filterHasPostalAddressContinentCode?: string, fields?: string, fieldsAddress?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.api+json'}): Observable<HttpResponse<LocationListResponse>>;
    public listLocation(pageSize?: number, pageNumber?: number, include?: 'hasPostalAddress', filterType?: 'Campus' | 'CampusPart' | 'Building' | 'BuildingPart' | 'Outside' | 'Floor' | 'FloorArea' | 'MultifloorArea' | 'Room' | 'RoomSegment', filterLabel?: any, filterExternalId?: any, filterTagsTagKey?: any, filterHasPostalAddressDataId?: any, filterIsPartOfDataId?: any, filterIsPartOfDataType?: 'Campus' | 'CampusPart' | 'Building' | 'BuildingPart' | 'Outside' | 'Floor' | 'MultifloorArea' | 'Area' | 'Room' | 'RoomSegment', filterBuildingType?: any, filterUsableAreaValue?: any, filterHasPostalAddressCountryCode?: string, filterHasPostalAddressContinentCode?: string, fields?: string, fieldsAddress?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.api+json'}): Observable<HttpEvent<LocationListResponse>>;
    public listLocation(pageSize?: number, pageNumber?: number, include?: 'hasPostalAddress', filterType?: 'Campus' | 'CampusPart' | 'Building' | 'BuildingPart' | 'Outside' | 'Floor' | 'FloorArea' | 'MultifloorArea' | 'Room' | 'RoomSegment', filterLabel?: any, filterExternalId?: any, filterTagsTagKey?: any, filterHasPostalAddressDataId?: any, filterIsPartOfDataId?: any, filterIsPartOfDataType?: 'Campus' | 'CampusPart' | 'Building' | 'BuildingPart' | 'Outside' | 'Floor' | 'MultifloorArea' | 'Area' | 'Room' | 'RoomSegment', filterBuildingType?: any, filterUsableAreaValue?: any, filterHasPostalAddressCountryCode?: string, filterHasPostalAddressContinentCode?: string, fields?: string, fieldsAddress?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/vnd.api+json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (pageSize !== undefined && pageSize !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>pageSize, 'page[size]');
        }
        if (pageNumber !== undefined && pageNumber !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>pageNumber, 'page[number]');
        }
        if (include !== undefined && include !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>include, 'include');
        }
        if (filterType !== undefined && filterType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>filterType, 'filter[type]');
        }
        if (filterLabel !== undefined && filterLabel !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>filterLabel, 'filter[label]');
        }
        if (filterExternalId !== undefined && filterExternalId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>filterExternalId, 'filter[externalId]');
        }
        if (filterTagsTagKey !== undefined && filterTagsTagKey !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>filterTagsTagKey, 'filter[tags.{tagKey}]');
        }
        if (filterHasPostalAddressDataId !== undefined && filterHasPostalAddressDataId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>filterHasPostalAddressDataId, 'filter[hasPostalAddress.data.id]');
        }
        if (filterIsPartOfDataId !== undefined && filterIsPartOfDataId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>filterIsPartOfDataId, 'filter[isPartOf.data.id]');
        }
        if (filterIsPartOfDataType !== undefined && filterIsPartOfDataType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>filterIsPartOfDataType, 'filter[isPartOf.data.type]');
        }
        if (filterBuildingType !== undefined && filterBuildingType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>filterBuildingType, 'filter[buildingType]');
        }
        if (filterUsableAreaValue !== undefined && filterUsableAreaValue !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>filterUsableAreaValue, 'filter[usableArea.value]');
        }
        if (filterHasPostalAddressCountryCode !== undefined && filterHasPostalAddressCountryCode !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>filterHasPostalAddressCountryCode, 'filter[hasPostalAddress.countryCode]');
        }
        if (filterHasPostalAddressContinentCode !== undefined && filterHasPostalAddressContinentCode !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>filterHasPostalAddressContinentCode, 'filter[hasPostalAddress.continentCode]');
        }
        if (fields !== undefined && fields !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>fields, 'fields');
        }
        if (fieldsAddress !== undefined && fieldsAddress !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>fieldsAddress, 'fields[Address]');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (service_auth) required
        credential = this.configuration.lookupCredential('service_auth');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/vnd.api+json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<LocationListResponse>(`${this.configuration.basePath}/locations`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
