// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .container-wizard .save {
  display: none;
}

.azure-import-wizard {
  block-size: 484px;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem;
}

.file-name {
  font-family: Siemens Sans;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
}

.date {
  font-family: Siemens Sans;
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
}

.title {
  font-family: Siemens Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

.layers-title {
  font-family: Siemens Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
}

.upload-progress {
  width: 70%;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
