import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  DeleteConfirmationDialogResult,
  ModalRef,
  SiActionDialogService,
  UploadFile,
} from '@simpl/element-ng';
import { BehaviorSubject } from 'rxjs';
import { ImportJobsRequestData } from '../../../apis/beff-api/model/import-jobs-request-data';
import { FileUploadHistoryService } from '../../../services/file-upload-history.service';
import { ImportService } from '../../../services/import.service';
import { NotificationService } from '../../../services/notification.service';
import {
  ACCEPTED_FILE_TYPES,
  EXPECTED_PROCESSING_HRS,
} from '../../constants/import.constants';
import { NewImportJobRequest } from '../../interfaces/import-methods.interface';

@Component({
  selector: 'app-pointr-import-modal',
  templateUrl: './pointr-import-modal.component.html',
  styleUrls: ['./pointr-import-modal.component.scss'],
})
export class PointrImportModalComponent implements OnInit {
  buildingId!: string;
  currentStep = 1;
  floorName!: string;
  floorNameValid = false;
  floorLvlValid = false;
  isLevelPresent = false;
  floorLvl!: string;
  file: UploadFile | undefined;
  isFloorDetailValid = false;
  partitionId!: string;
  progressCount$!: BehaviorSubject<number>;
  expProcessingTime = EXPECTED_PROCESSING_HRS.POINTER_S;
  azureAcceptedFileTypes = ACCEPTED_FILE_TYPES.POINTR;

  constructor(
    public modalRef: ModalRef,
    private importService: ImportService,
    private readonly siModal: SiActionDialogService,
    private translateService: TranslateService,
    private fileUploadHistoryService: FileUploadHistoryService,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.progressCount$ = new BehaviorSubject<number>(0);
  }

  closeModal() {
    if (
      this.currentStep === 1 &&
      this.file?.status !== 'invalid' &&
      this.file?.status === 'added'
    ) {
      this.siModal
        .showDeleteConfirmationDialog(
          this.translateService.instant(
            'DELETE_CONFIRM_DIALOG.CANCEL_CONFIRM_MSG'
          ),
          this.translateService.instant(
            'DELETE_CONFIRM_DIALOG.CANCEL_CONFIRM_HEAD'
          ),
          this.translateService.instant(
            'DELETE_CONFIRM_DIALOG.CANCEL_CONFIRM_LEAVE'
          )
        )
        .subscribe(result => {
          switch (result) {
            case DeleteConfirmationDialogResult.Delete:
              this.modalRef?.hide();
              break;
            case DeleteConfirmationDialogResult.Cancel:
              break;
          }
        });
    } else {
      this.modalRef.hide();
    }
  }

  fileUpload() {
    this.currentStep = 2;
    this.progressCount$.next(1);
    const importRequest: NewImportJobRequest = {
      partitionId: this.partitionId,
      buildingId: this.buildingId,
      importJobType: ImportJobsRequestData.JobTypeEnum.Pointr,
      floorName: this.floorName,
      file: this.file,
      progressCount: this.progressCount$,
      floorLvl: Number(this.floorLvl),
    };
    this.importService.startImport(importRequest).subscribe({
      next: () => {
        this.progressCount$.next(100);
        this.currentStep = 3;
        this.fileUploadHistoryService.addFloorLevelForNewImport(
          Number(this.floorLvl)
        );
        this.notificationService.showSuccess(
          this.translateService.instant('NOTIFICATIONS.IMPORT_SUCCESS_TITLE'),
          this.translateService.instant('NOTIFICATIONS.IMPORT_SUCCESS_MSG')
        );
      },
      error: () => {
        this.file = undefined;
        this.closeModal();
      },
    });
  }

  updateFileInfo(event: UploadFile) {
    this.file = event;
  }

  updateFloorInfoValidity(event: any) {
    const availableFloorLevels =
      this.fileUploadHistoryService.getAvailableFloorLvls();
    if ('floorName' in event) {
      const valityDetails = this.importService.isFloorNameValid(event);
      this.floorName = valityDetails.floorName;
      this.floorNameValid = valityDetails.valid;
    } else {
      const valityDetails = this.importService.isFloorLvlValid(
        event,
        availableFloorLevels
      );
      this.floorLvl = valityDetails.floorLvl;
      this.floorLvlValid = valityDetails.valid;
      this.isLevelPresent = valityDetails.isNumberPresent;
    }
    this.isFloorDetailValid = this.floorNameValid && this.floorLvlValid;
  }
}
