import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ModalRef, SiModalService } from '@simpl/element-ng';
import { IMPORT_METHODS } from '../../constants/import.constants';
import { ImportMethod } from '../../interfaces/import-methods.interface';
import { AzureImportModalComponent } from '../azure-import-modal/azure-import-modal.component';
import { PointrImportModalComponent } from '../pointr-import-modal/pointr-import-modal.component';

@Component({
  selector: 'app-import-selector',
  templateUrl: './import-selector.component.html',
  styleUrls: ['./import-selector.component.scss'],
})
export class ImportSelectorComponent implements OnInit {
  importMethods: ImportMethod[] = [];
  selectedMethod = '';
  partitionId?: string;
  buildingId?: string;
  permissionDetails = {
    AZR: false,
    PTR: false,
  };
  constructor(
    public modalRef: ModalRef,
    private translate: TranslateService,
    private modalService: SiModalService
  ) {}

  ngOnInit(): void {
    this.importMethods = [...IMPORT_METHODS];
    this.importMethods = this.performTranslations(this.importMethods);
    this.importMethods = this.addPermissionDetails(
      this.permissionDetails,
      this.importMethods
    );
  }

  addPermissionDetails(
    permissionDetails: { AZR: boolean; PTR: boolean },
    importMethods: ImportMethod[]
  ): ImportMethod[] {
    importMethods.forEach(method => {
      method.enabled =
        permissionDetails[method.ID as keyof { AZR: boolean; PTR: boolean }];
    });
    return importMethods;
  }

  performTranslations(importMethods: ImportMethod[]) {
    importMethods.forEach(method => {
      method.METHOD_NAME = this.translate.instant(method.METHOD_NAME);
      method.METHOD_DESCRIPTION = this.translate.instant(
        method.METHOD_DESCRIPTION
      );
    });
    return importMethods;
  }

  detailByValue(index: any, detail: any) {
    return detail[1];
  }

  onClose() {
    this.modalRef.hide();
  }

  onImportMethodSelect() {
    this.onClose();
    //timeout is used so that the Modal overlay is properly applied
    // without this the new modal overlay isn't getting applied
    setTimeout(() => {
      if (this.selectedMethod === 'AZR')
        this.modalService.show(AzureImportModalComponent, {
          keyboard: false,
          initialState: {
            partitionId: this.partitionId,
            buildingId: this.buildingId,
          },
        });
      else if (this.selectedMethod === 'PTR')
        this.modalService.show(PointrImportModalComponent, {
          keyboard: false,
          initialState: {
            partitionId: this.partitionId,
            buildingId: this.buildingId,
          },
        });
    }, 500);
  }
}
