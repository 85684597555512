/* eslint-disable @typescript-eslint/no-var-requires */
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  SimplLivePreviewModule,
  SimplLivePreviewRoutingModule,
} from '@simpl/live-preview';
import { PlanImportNgModule } from 'plan-import-ng';

import { CommonModule } from '@angular/common';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import {
  SiActionDialogService,
  SiLandingPageModule,
  SiLoadingSpinnerModule,
} from '@simpl/element-ng';
import { SiTranslateNgxTModule } from '@simpl/element-ng/ngx-translate';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { AppComponent } from './app.component';
import { TokenInterceptor } from 'projects/plan-import-ng/src/lib/services/token.interceptor';
// eslint-disable-next-line @typescript-eslint/no-require-imports, @typescript-eslint/no-var-requires
const componentLoader =
  // eslint-disable-next-line @typescript-eslint/no-require-imports
  require('@simpl/live-preview/component-loader?root=src&examples=app/examples/**/*.ts!./app.module.ts').default;

export const httpLoaderFactory = (http: HttpClient) =>
  new MultiTranslateHttpLoader(http, [
    { prefix: '../assets/i18n/', suffix: '.json' },
    {
      prefix: '../assets/i18n/imported/',
      suffix: '.json',
    },
    {
      prefix: '../assets/i18n/no-translations/',
      suffix: '.json',
    },
    {
      prefix: '../assets/i18n/imported/no-translations/',
      suffix: '.json',
    },
  ]);
@NgModule({
  declarations: [AppComponent],
  imports: [
    SiLandingPageModule,
    SiLoadingSpinnerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient],
      },
      defaultLanguage: 'template',
    }),
    BrowserModule,
    HttpClientModule,
    SimplLivePreviewModule.forRoot({
      modules: [
        PlanImportNgModule.forRoot(),
        CommonModule,
        SiTranslateNgxTModule,
      ],
      componentLoader,
      examplesBaseUrl: 'app/examples/',
      ticketBaseUrl:
        'https://code.siemens.com/horizon/apps/plan-import/issues/new',
      themeSwitcher: false,
    }),
    SimplLivePreviewRoutingModule,
    BrowserAnimationsModule,
    SiTranslateNgxTModule,
  ],
  providers: [
    SiActionDialogService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
