import { Injectable } from '@angular/core';
import { SiToastNotificationService } from '@simpl/element-ng';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private toastNotificationService: SiToastNotificationService) {}

  showError(title: string, msg: string) {
    this.toastNotificationService.queueToastNotification(
      'danger',
      title,
      msg,
      false,
      false
    );
  }

  showWarning(title: string, msg: string) {
    this.toastNotificationService.queueToastNotification(
      'warning',
      title,
      msg,
      false,
      false
    );
  }

  showSuccess(title: string, msg: string) {
    this.toastNotificationService.queueToastNotification(
      'success',
      title,
      msg,
      false,
      false
    );
  }

  showInfo(title: string, msg: string) {
    this.toastNotificationService.queueToastNotification(
      'info',
      title,
      msg,
      false,
      false
    );
  }
}
