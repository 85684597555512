/**
 * Authorization Decision Service
 *  # Authorization A valid authorization header with a machine bearer token has to be provided. The bearer token is expected as JSON Web Token (JWT). You may use jwt.io to decode any token you have.  # AoDS V2 AoDS V2 endpoints are disabled by default. (Feature Toggle)  # Further information Please contact the team42 for any questions and please report issues and possible improvements. 
 *
 * The version of the OpenAPI document: 1.0.0-oas3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * error according to https://datatracker.ietf.org/doc/html/rfc6749#section-5.2
 */
export interface ErrorsResponseRFC6749 { 
    /**
     * A single ASCII [USASCII] error code
     */
    error: ErrorsResponseRFC6749.ErrorEnum;
    /**
     * OPTIONAL, parameter MUST NOT include characters outside the set %x20-21 / %x23-5B / %x5D-7E
     */
    error_description?: string;
    /**
     * OPTIONAL, MUST NOT include characters outside the set %x21 / %x23-5B / %x5D-7E
     */
    error_uri?: string;
}
export namespace ErrorsResponseRFC6749 {
    export type ErrorEnum = 'invalid_request' | 'invalid_client' | 'invalid_grant' | 'unauthorized_client' | 'unsupported_grant_type' | 'invalid_scope' | 'internal_server_error';
    export const ErrorEnum = {
        InvalidRequest: 'invalid_request' as ErrorEnum,
        InvalidClient: 'invalid_client' as ErrorEnum,
        InvalidGrant: 'invalid_grant' as ErrorEnum,
        UnauthorizedClient: 'unauthorized_client' as ErrorEnum,
        UnsupportedGrantType: 'unsupported_grant_type' as ErrorEnum,
        InvalidScope: 'invalid_scope' as ErrorEnum,
        InternalServerError: 'internal_server_error' as ErrorEnum
    };
}


