/**
 * Plan Import App BFF
 * Plan Import App BFF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FloorplanRelationships } from './floorplan-relationships';


export interface PointrImportJobsRequestData { 
    /**
     * File name
     */
    fileName: string;
    /**
     * type of file
     */
    fileType: string;
    /**
     * Type of import method
     */
    jobType: PointrImportJobsRequestData.JobTypeEnum;
    relationships: FloorplanRelationships;
}
export namespace PointrImportJobsRequestData {
    export type JobTypeEnum = 'AZURE' | 'POINTR';
    export const JobTypeEnum = {
        Azure: 'AZURE' as JobTypeEnum,
        Pointr: 'POINTR' as JobTypeEnum
    };
}


