/**
 * Plan Import App BFF
 * Plan Import App BFF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Artifacts } from './artifacts';
import { ImportJobsResponseDataAttributesMeta } from './import-jobs-response-data-attributes-meta';
import { FloorplanRelationships } from './floorplan-relationships';


export interface ImportJobsResponseDataAttributes { 
    /**
     * Type of import method
     */
    jobType: ImportJobsResponseDataAttributes.JobTypeEnum;
    /**
     * url to upload the Dwg file, which will be active for 10 min.
     */
    fileUploadUrl: string;
    artifacts?: Artifacts;
    relationships: FloorplanRelationships;
    meta: ImportJobsResponseDataAttributesMeta;
}
export namespace ImportJobsResponseDataAttributes {
    export type JobTypeEnum = 'AZURE' | 'POINTR';
    export const JobTypeEnum = {
        Azure: 'AZURE' as JobTypeEnum,
        Pointr: 'POINTR' as JobTypeEnum
    };
}


