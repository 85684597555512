import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ColumnMode, TableColumn } from '@siemens/ngx-datatable';
import {
  BackgroundColorVariant,
  SI_DATATABLE_CONFIG,
  SearchCriteria,
  SiFilteredSearchComponent,
} from '@simpl/element-ng';
import { Subscription } from 'rxjs';

import { DatePipe } from '@angular/common';
import { StateBeffService } from '../../apis/beff-api/api/api';
import { ImportJobStatus } from '../../apis/beff-api/model/import-job-status';
import { FileUploadHistoryService } from '../../services/file-upload-history.service';
import { ImportService } from '../../services/import.service';
import { NotificationService } from '../../services/notification.service';
import { IMPORT_STATE_CHANGE_REQUEST } from '../../services/services.constants';
import { TABLE_PROPS } from '../constants/import.constants';
import {
  HistoryTablePermissions,
  ImportJobDetails,
  Page,
  PageRequest,
} from '../interfaces/file-upload-history.interface';
import { GetImportJobsResponseDataAttributes } from '../../apis/beff-api/model/get-import-jobs-response-data-attributes';
@Component({
  selector: 'app-file-upload-history',
  templateUrl: './file-upload-history.component.html',
  styleUrls: ['./file-upload-history.component.scss'],
})
export class FileUploadHistoryComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input() isJobDetailsLoading = false;
  @Input() partitionId: string;
  @Input() permissions: HistoryTablePermissions;

  //store original rows fetch on initial load
  @Input() jobImportDetails: ImportJobDetails[] | null = null;

  // Template refs for ngx-datatable cellTemplate
  @ViewChild('serviceCellTempl', { static: true })
  private serviceCellTempl!: TemplateRef<any>;
  @ViewChild('search') private search!: SiFilteredSearchComponent;
  @ViewChild('statusCellTempl', { static: true })
  statusCellTempl!: TemplateRef<any>;
  @ViewChild('importBtnTemplate', { static: true })
  importBtnTemplate!: TemplateRef<any>;

  searchCompColorvariant: BackgroundColorVariant = 'base-0';
  showIcon = true;
  disableFreeTextSearch = false;
  searchPlaceholder = this.translate.instant('COMMON_APP_TEXT.SEARCH');
  public displayInfoMap = new Map<string, string>();
  predifinedSearchCriteria: SearchCriteria = { criteria: [], value: '' };

  // criteria defined for searching rows in job table
  criteria = [
    { name: this.translate.instant('SEARCH_CRITERIA.FLOOR') },
    { name: this.translate.instant('SEARCH_CRITERIA.FLOOR_LEVEL') },
    { name: this.translate.instant('SEARCH_CRITERIA.UPLOADED_DATE') },
    {
      name: this.translate.instant('SEARCH_CRITERIA.EXPECTED_COMPLETION_TIME'),
    },
    { name: this.translate.instant('SEARCH_CRITERIA.TYPE') },
    { name: this.translate.instant('SEARCH_CRITERIA.SERVICE') },
    { name: this.translate.instant('SEARCH_CRITERIA.STATUS') },
  ];

  tableConfig = SI_DATATABLE_CONFIG;
  columns!: TableColumn[];
  page = new Page();

  // we have two variables for storage of table info
  // one will store original rows fetch on initial load
  // other will be used for what will be shown in table after filtering and other operations
  tableRows = new Array<ImportJobDetails>();
  filteredTableRows = new Array<ImportJobDetails>();

  protected readonly ColumnMode = ColumnMode;

  isLoading = false;
  currentSearchEvent: any = null;
  refreshSub$: Subscription;

  constructor(
    private translate: TranslateService,
    private importService: ImportService,
    private fileUploadHistoryService: FileUploadHistoryService,
    private _datePipe: DatePipe,
    private stateBeffService: StateBeffService,
    private notificationService: NotificationService
  ) {
    this.page.size = 10;
  }

  ngOnInit() {
    this.initTableColumns();
    this.listenToNewlyAddedJobEmission();
  }

  ngOnChanges(): void {
    if (this.jobImportDetails && this.jobImportDetails.length) {
      this.resetPage(JSON.parse(JSON.stringify(this.jobImportDetails)));
    }
    if (this.currentSearchEvent) {
      this.predifinedSearchCriteria = {
        criteria: this.currentSearchEvent.criteria,
        value: this.currentSearchEvent.value,
      };
      this.onSearch(this.currentSearchEvent);
    }
  }

  listenToNewlyAddedJobEmission() {
    this.importService.newUploadJobDetails.subscribe(res => {
      if (res) {
        this.jobImportDetails = [...this.jobImportDetails, res];
        if (this.currentSearchEvent) {
          this.predifinedSearchCriteria = {
            criteria: this.currentSearchEvent.criteria,
            value: this.currentSearchEvent.value,
          };
          this.onSearch(this.currentSearchEvent);
        } else {
          this.resetPage(JSON.parse(JSON.stringify(this.jobImportDetails)));
        }
      }
    });
  }

  sortTableData(tableRows: ImportJobDetails[]): ImportJobDetails[] {
    return tableRows.sort(
      (row1, row2) => Number(row1.floorLevel) - Number(row2.floorLevel)
    );
  }

  clear() {
    this.search.deleteAllCriteria();
  }

  ngOnDestroy() {
    this.refreshSub$?.unsubscribe();
  }

  //Method to get paginated response for table
  setPage(pageRequest: PageRequest) {
    this.isLoading = true;
    if (this.currentSearchEvent) {
      pageRequest.filteredElements = this.filteredTableRows;
    } else {
      pageRequest.filteredElements = [...this.jobImportDetails];
    }
    this.sortTableData(pageRequest.filteredElements);
    let pagedData = this.fileUploadHistoryService.getPagedData(pageRequest);
    this.page = pagedData.page;
    this.tableRows = pagedData.data;
    this.isLoading = false;
  }

  private initTableColumns(): void {
    this.columns = [
      {
        prop: TABLE_PROPS.FLOOR_NAME,
        name: this.translate.instant('SEARCH_CRITERIA.FLOOR'),
        resizeable: false,
        canAutoResize: true,
        minWidth: 180,
      },
      {
        prop: TABLE_PROPS.FLOOR_LEVEL,
        name: this.translate.instant('SEARCH_CRITERIA.FLOOR_LEVEL'),
        resizeable: false,
        canAutoResize: true,
        minWidth: 120,
      },
      {
        prop: TABLE_PROPS.UPLOADED_DT,
        name: this.translate.instant('SEARCH_CRITERIA.UPLOADED_DATE'),
        resizeable: false,
        canAutoResize: true,
        minWidth: 200,
        pipe: this.datePipe(),
      },
      {
        prop: TABLE_PROPS.EXPECTED_TIME,
        name: this.translate.instant(
          'SEARCH_CRITERIA.EXPECTED_COMPLETION_TIME'
        ),
        resizeable: false,
        canAutoResize: true,
        minWidth: 200,
        pipe: this.datePipe(),
      },
      {
        prop: TABLE_PROPS.TYPE,
        name: this.translate.instant('SEARCH_CRITERIA.TYPE'),
        resizeable: false,
        canAutoResize: true,
        minWidth: 100,
      },
      {
        prop: TABLE_PROPS.SERVICE,
        name: this.translate.instant('SEARCH_CRITERIA.SERVICE'),
        resizeable: false,
        canAutoResize: true,
        minWidth: 100,
        cellTemplate: this.serviceCellTempl,
      },
      {
        prop: TABLE_PROPS.STATUS,
        name: this.translate.instant('SEARCH_CRITERIA.STATUS'),
        resizeable: false,
        canAutoResize: true,
        cellTemplate: this.statusCellTempl,
        minWidth: 140,
      },
      {
        prop: '',
        name: '',
        resizeable: false,
        canAutoResize: true,
        cellTemplate: this.importBtnTemplate,
        minWidth: 140,
      },
    ];
  }

  onSearch(event: any) {
    if (event && event.criteria) {
      this.currentSearchEvent = event;
      if (
        !event ||
        ((event.criteria.length === 0 ||
          !event.criteria.filter((criterion: any) => criterion.value !== '')
            .length) &&
          event.value === '')
      ) {
        // when no search criteria present
        // getting original rows and resetting
        this.filteredTableRows = JSON.parse(
          JSON.stringify(this.jobImportDetails)
        );
        this.resetPage(this.filteredTableRows);
        return;
      }
      //when search criteria present
      // getting filtered rows and resetting
      this.filteredTableRows = this.jobImportDetails.filter(floor =>
        this.filterFunction(floor, event.criteria)
      );

      this.resetPage(this.filteredTableRows);
    }
  }

  filterFunction(floor: ImportJobDetails, criteria: any): boolean {
    return this.importService.filterFunction(floor, criteria);
  }

  datePipe() {
    return {
      transform: (value: string | number | Date) =>
        this._datePipe.transform(value, 'YYYY/MM/dd - h:mm:ss a'),
    };
  }

  //Method to reset data and to get pagination as per the search criteria data
  resetPage(filteredTableRows: ImportJobDetails[]) {
    const page: PageRequest = {
      offset: 0,
      pageSize: this.page.size,
      filteredElements: filteredTableRows,
    };
    this.setPage(page);
  }

  importIntoFloorPlan(row: ImportJobDetails) {
    this.isJobDetailsLoading = true;
    const importingState: ImportJobStatus = 'IMPORTING';
    this.stateBeffService
      .changeImportJobState(
        this.partitionId,
        row.floorId,
        row.importJobId,
        importingState
      )
      .subscribe({
        next: () => {
          this.isJobDetailsLoading = false;
          const title = 'Success';
          const msg = this.translate.instant(
            IMPORT_STATE_CHANGE_REQUEST.IMPORTING_SUCCESS
          );
          this.notificationService.showSuccess(title, msg);
          row.status = 'IMPORTING';
        },
        error: () => {
          this.isJobDetailsLoading = false;
          const title = 'Failed';
          const msg = this.translate.instant(
            IMPORT_STATE_CHANGE_REQUEST.IMPORTING_FAILURE
          );
          this.notificationService.showSuccess(title, msg);
        },
      });
  }

  isImportButtonDisabled(row: ImportJobDetails) {
    const convertedStatus =
      GetImportJobsResponseDataAttributes.StatusEnum.Converted;
    const failedStatus = GetImportJobsResponseDataAttributes.StatusEnum.Failed;
    const processableFailureType =
      GetImportJobsResponseDataAttributes.FailureTypeEnum.Processable;
    if (
      row?.status === convertedStatus ||
      (row.status === failedStatus &&
        row?.failureType &&
        row?.failureType === processableFailureType)
    ) {
      return false;
    } else {
      return true;
    }
  }
}
