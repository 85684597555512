import { CommonModule, DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgxDatatableModule } from '@siemens/ngx-datatable';
import {
  SiAboutModule,
  SiCardModule,
  SiDatatableModule,
  SiFilteredSearchModule,
  SiLandingPageModule,
  SiLoadingSpinnerModule,
  SimplElementNgModule,
  SiNavbarModule,
  SiSearchBarModule,
  SiToastNotificationService,
  SiTooltipModule,
} from '@simpl/element-ng';
import { FileUploadHistoryComponent } from './components/file-upload-history/file-upload-history.component';
import { AzureImportModalComponent } from './components/modals/azure-import-modal/azure-import-modal.component';
import { ImportSelectorComponent } from './components/modals/import-selector/import-selector.component';
import { PointrImportModalComponent } from './components/modals/pointr-import-modal/pointr-import-modal.component';
import { FileUploadComponent } from './components/reusable/file-upload/file-upload.component';
import { FloorInfoInputComponent } from './components/reusable/floor-info-input/floor-info-input.component';
import { ImportProgressComponent } from './components/reusable/import-progress/import-progress.component';
import { ImportSuccessComponent } from './components/reusable/import-success/import-success.component';
import { PlanImportComponent } from './plan-import.component';
import { TokenInterceptor } from './services/token.interceptor';
import { ImportStatusTranslatePipe } from './pipes/import-status-tranlate.pipe';

@NgModule({
  declarations: [
    PlanImportComponent,
    AzureImportModalComponent,
    FileUploadHistoryComponent,
    ImportSelectorComponent,
    PointrImportModalComponent,
    FileUploadComponent,
    FloorInfoInputComponent,
    ImportProgressComponent,
    ImportSuccessComponent,
    ImportStatusTranslatePipe,
  ],
  imports: [
    FormsModule,
    HttpClientModule,
    NgxDatatableModule,
    SiAboutModule,
    SiCardModule,
    SiDatatableModule,
    SiFilteredSearchModule,
    SiLandingPageModule,
    SiLoadingSpinnerModule,
    SimplElementNgModule,
    SiNavbarModule,
    SiSearchBarModule,
    CommonModule,
    SiTooltipModule,
    TranslateModule,
  ],
  providers: [
    SiToastNotificationService,
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
  ],
  exports: [PlanImportComponent],
})
export class PlanImportNgModule {
  static forRoot(): ModuleWithProviders<PlanImportNgModule> {
    return {
      ngModule: PlanImportNgModule,
    };
  }
}
