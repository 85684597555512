/**
 * Plan Import App BFF
 * Plan Import App BFF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface FileUrlRequestDataAttributesArtifacts { 
    /**
     * artifact Id
     */
    artifactId: string;
    /**
     * File name
     */
    fileName: string;
    /**
     * type of file example- dwg, pdf, dxf
     */
    fileType: string;
    action: FileUrlRequestDataAttributesArtifacts.ActionEnum;
}
export namespace FileUrlRequestDataAttributesArtifacts {
    export type ActionEnum = 'fileUploadUrl' | 'fileDownloadUrl';
    export const ActionEnum = {
        FileUploadUrl: 'fileUploadUrl' as ActionEnum,
        FileDownloadUrl: 'fileDownloadUrl' as ActionEnum
    };
}


