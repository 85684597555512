/**
 * Plan Import App BFF
 * Plan Import App BFF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { GetImportJobsResponseDataAttributes } from './get-import-jobs-response-data-attributes';


export interface GetImportJobsResponseData { 
    /**
     * Import Job Id
     */
    id: string;
    type: GetImportJobsResponseData.TypeEnum;
    attributes: GetImportJobsResponseDataAttributes;
}
export namespace GetImportJobsResponseData {
    export type TypeEnum = 'ImportJob';
    export const TypeEnum = {
        ImportJob: 'ImportJob' as TypeEnum
    };
}


