/**
 * BX Location Service v2
 * Location Service implementation is following: - Location model: [BTLO 1.1](https://bt.schema.siemens.io/bt/shared/btlo/version/1.1) - Zones model: [BTZF 2.2](https://bt.schema.siemens.io/bt/shared/btzf/version/2.2) 
 *
 * The version of the OpenAPI document: 2.5.12
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EnumCountryAttributes } from './enumCountryAttributes';


/**
 * Represents a Country
 */
export interface EnumCountry { 
    /**
     * ID of this object
     */
    id: string;
    /**
     * Type of this object
     */
    type: EnumCountry.TypeEnum;
    attributes?: EnumCountryAttributes;
}
export namespace EnumCountry {
    export type TypeEnum = 'Country';
    export const TypeEnum = {
        Country: 'Country' as TypeEnum
    };
}


