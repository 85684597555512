/**
 * Plan Import App BFF
 * Plan Import App BFF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FileUrlRequestDataAttributes } from './file-url-request-data-attributes';


export interface FileUrlRequestData { 
    /**
     * Import Job Id
     */
    id: string;
    type: FileUrlRequestData.TypeEnum;
    attributes: FileUrlRequestDataAttributes;
}
export namespace FileUrlRequestData {
    export type TypeEnum = 'ImportJob';
    export const TypeEnum = {
        ImportJob: 'ImportJob' as TypeEnum
    };
}


