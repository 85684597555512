// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.import-wizard {
  block-size: 484px;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem;
}

::ng-deep .container-wizard .save {
  display: none;
}

.azure-import-wizard {
  block-size: 484px;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem;
}

.upload-progress {
  width: 70%;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
