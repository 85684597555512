import { Component, Input } from '@angular/core';
import { ModalRef, UploadFile } from '@simpl/element-ng';

@Component({
  selector: 'app-import-success',
  templateUrl: './import-success.component.html',
  styleUrls: ['./import-success.component.scss'],
})
export class ImportSuccessComponent {
  constructor(public modalRef: ModalRef) {}

  @Input() file: UploadFile | undefined;
  @Input() expectedTime: string;

  closeModal() {
    this.modalRef.hide();
  }
}
