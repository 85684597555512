/**
 * BX Location Service v2
 * Location Service implementation is following: - Location model: [BTLO 1.1](https://bt.schema.siemens.io/bt/shared/btlo/version/1.1) - Zones model: [BTZF 2.2](https://bt.schema.siemens.io/bt/shared/btzf/version/2.2) 
 *
 * The version of the OpenAPI document: 2.5.12
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface OutsideRelationshipsIsOutsideOfData { 
    id: string;
    type: OutsideRelationshipsIsOutsideOfData.TypeEnum;
}
export namespace OutsideRelationshipsIsOutsideOfData {
    export type TypeEnum = 'Campus' | 'CampusPart' | 'Building' | 'BuildingPart';
    export const TypeEnum = {
        Campus: 'Campus' as TypeEnum,
        CampusPart: 'CampusPart' as TypeEnum,
        Building: 'Building' as TypeEnum,
        BuildingPart: 'BuildingPart' as TypeEnum
    };
}


