import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-import-progress',
  templateUrl: './import-progress.component.html',
  styleUrls: ['./import-progress.component.scss'],
})
export class ImportProgressComponent {
  @Input() progressCount: number = 0 as number;
}
